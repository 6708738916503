<template>
  <el-dialog title="现转移到" :visible.sync="openDialog" width="500px" :show-close="true" class="dialog-vertical"
    @close="cancel('transFormData')">
    <el-form size="medium" :model="transFormData" :rules="rules" label-position="right" label-width="90px"
      class="small-form" ref="transFormData">
      <el-form-item :label="tenantVersion === 'school' ? '所属校友会：':'组织架构：'" prop="ids">
        <el-cascader style="width: 80%" v-model="transFormData.ids" :options="parentArr" ref="CascaderRef"
          :props="{ value: 'id', label: 'name', checkStrictly: true,expandTrigger: 'hover' }"
          placeholder="请选择" @change="handleSelect"></el-cascader>
      </el-form-item>
      <el-form-item label="会内职务：" prop="new_position_id">
        <el-select v-model="transFormData.new_position_id" :placeholder="
            !transFormData.ids.length ? tenantVersion === 'school' ? '请先选择所属校友会':'请先选择组织架构' : loadingPosition?'职务加载中...':'请选择会内职务'
          " style="width: 80%" :disabled="!transFormData.ids.length || loadingPosition">
          <el-option v-for="item in postList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" @click="submit('transFormData')" :loading="saveLoading"
        :disabled="loadingPosition">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  ParentList,
  PostOptions,
  MovePosition,
} from "../../api/meeting-post/post-list";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    ID: {
      type: [Number, String],
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      parentArr: [],
      postList: [],
      transFormData: {
        old_position_id: -1,
        new_position_id: "",
        join_organization_id: "",
        ids: [],
      },
      rules: {
        ids: [{ required: true, validator: this.ids, trigger: "change" }],
        new_position_id: [
          { required: true, message: "请选择会内职务", trigger: "change" },
        ],
      },
      loadingPosition: false,
      saveLoading: false,
    };
  },
  methods: {
    // 获取架构列表
    getParentList() {
      ParentList({ is_show_none: 0 })
        .then((res) => {
          this.parentArr = res.data;
        })
        .catch((err) => {});
    },
    // 选择组织架构操作
    handleSelect(e) {
      const data = {};
      data.organization_id = e[e.length - 1];
      data.is_show_prepare = 0;
      this.transFormData.join_organization_id = e[e.length - 1];
      this.loadingPosition = true;
      this.$refs.CascaderRef.dropDownVisible = false
      // 获取该架构下的职务
      PostOptions(data)
        .then((res) => {
          this.loadingPosition = false;
          this.postList = res.data;
        })
        .catch((err) => {
          this.loadingPosition = false;
        });
    },
    // 组织架构验证
    ids(rule, value, callback) {
      if (value != undefined && value.length) {
        callback();
      } else {
        callback(callback(new Error("请选择组织架构")));
      }
    },
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          const data = { ...this.transFormData };
          MovePosition(data)
            .then((res) => {
              this.$message.success("迁移成功");
              this.updateList();
              this.saveLoading = false;
              this.openDialog = false;
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
      this.$refs[formName].resetFields();
    },
  },
  created() {
    this.getParentList();
  },
  watch: {
    ID(val) {
      this.transFormData.old_position_id = val;
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
  },
};
</script>

<style>
</style>
