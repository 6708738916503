import api from "@/base/utils/request";

// 获取职务详情
export const positionDetail = data => {
  return api({
    url: "/admin/cyc/position/detail",
    method: "post",
    data
  });
};

// 获取上级组织列表
export const ParentList = data => {
  return api({
    url: "/admin/cyc/Organization/OrganizationsTree",
    method: "post",
    data
  });
};

// 保存职务
export const SavePosition = data => {
  return api({
    url: "/admin/cyc/position/save",
    method: "post",
    data,
    notCancel: true
  });
};