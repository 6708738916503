<template>
  <div>
    <el-dialog
      class="dialog-vertical"
      width="582px"
      height="432px"
      :visible="openDialog"
      title="转移"
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="true"
      :before-close="beforeClose"
      @close="openDialog = false"
    >
      <div v-if="all === -1" class="btn">
        <el-button @click="selectAll">
          <p>架构内所有会员一并转移</p>
          <!--          <p>（点击后在弹窗中选择架构职务）</p>-->
        </el-button>
        <el-button @click="select()">
          <p>架构内部分会员转移</p>
          <p>（点击后在弹窗中选择会员数据）</p>
        </el-button>
      </div>
      <!--      <div class="flex-center" style="height: 200px">-->
      <!--        <el-radio-group v-model="all" class="flex flex-col">-->
      <!--          <el-radio @click.native="selectAll" :label="1"-->
      <!--            >架构内所有会员一并转移</el-radio-->
      <!--          >-->
      <!--          <el-radio @click.native="select" style="margin-top: 10px" :label="0"-->
      <!--            >架构内部分会员转移</el-radio-->
      <!--          >-->
      <!--        </el-radio-group>-->
      <!--      </div>-->
      <div v-else-if="!selectedUsers.length" class="btn"><div class="el-button"></div></div>
      <div class="flex-col" style="width: 100%">
        <span
          class="user-progress flex-between"
          :data-progress="user.progress + '%'"
          :style="{
            '--progress-number': user.progress + '%',
          }"
          v-for="(user, userI) in selectedUsers"
          >{{ user.name
          }}<span
            v-if="user.errMsg"
            :style="{ color: user.status === 1 ? '#13CE66' : 'red' }"
            >【{{ user.errMsg }}】</span
          >
          <span v-else-if="saveLoading && user.status === -1">【处理中...】</span>
          <i
            v-if="!saveLoading && !user.errMsg"
            @click="selectedUsers.splice(userI, 1)"
            class="el-icon-close"
          ></i>
        </span>
      </div>
      <div v-if="all !== -1" slot="footer">
        <el-button v-if="!saveLoading" @click="openDialog = false">{{
          selectedUsers.filter((s) => s.status !== -1).length === 0
            ? '取消'
            : '关闭'
        }}</el-button>
        <el-button
          v-if="
            transferForm &&
            selectedUsers.length &&
            selectedUsers.filter((s) => s.status !== -1).length === 0
          "
          type="primary"
          :loading="saveLoading"
          :disabled="loadingPosition"
          @click="doTransfer()"
          >转移</el-button
        >
        <el-button
          v-else-if="!transferForm"
          type="primary"
          :loading="saveLoading"
          @click="submit('transFormData')"
          >下一步</el-button
        >
      </div>
    </el-dialog>

    <transfer-user-selector
      v-model="openUsers"
      :members="selectedUsers"
      row-key="user_position_id"
      :positionId="ID"
      @changeSelect="onSelect"
      @closeDialog="onCloseUsers"
    />

    <position-user-change-position
      v-model="openPosition"
      @confirm="transfer"
      @closeDialog="onClosePosition"
    />
  </div>
</template>

<script>
import TransferUserSelector from './transfer-user-selector'
import PositionUserChangePosition from './position-user-change-position'

import AQM from '@/base/utils/async-queue-manager' //异步队列管理
var manager = null

export default {
  name: 'position-users-transfer',
  components: {
    PositionUserChangePosition,
    TransferUserSelector,
  },
  model: {
    prop: 'open',
    event: 'closeDialog',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    ID: {
      type: [Number, String],
    },
    OID: {
      type: [Number, String],
    }, // 没用到
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      loadingPosition: false,
      saveLoading: false,
      all: -1,
      users: [], // 主要是拿 user_position_id
      selectedUsers: [], // 主要是拿 user_id
      openUsers: false,
      openPosition: false,
      transferForm: null,
      clickTransferButton: false,
    }
  },
  computed: {
    openDialog: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('closeDialog', val)
      },
    },
  },
  watch: {
    openDialog(val) {
      if (val) {
        this.reset()
        this.all = -1
      } else {
        this.saveLoading = false
        if (this.clickTransferButton) {
          this.updateList()
        }
      }
    },
    'selectedUsers.length'(val, oldVal) {
      if (oldVal > 0 && val === 0) {
        // 删到没数据时
        this.all = -1
      }
    },
  },
  methods: {
    beforeClose(done) {
      if (this.saveLoading) return
      done()
    },
    onCloseUsers() {
      if (this.selectedUsers.length === 0) {
        // 没有选会员，退出所有弹窗
        this.openDialog = false
      }
    },
    onClosePosition() {
      if (this.transferForm === null) {
        // 没有选职务，退出所有弹窗
        this.openDialog = false
      }
    },
    reset() {
      this.users = []
      this.selectedUsers = []
      this.transferForm = null
      this.clickTransferButton = false
    },
    selectAll() {
      this.all = 1
      this.reset()

      this.getPositionUsers()
      this.openPosition = true
    },
    select() {
      this.all = 0
      this.reset()

      // 选择会员弹窗，多选
      this.openUsers = true
    },
    onSelect(dataList) {
      /**
       * @example
       [{
        avatar: "https://testsaasres.shetuan.pro/cos/qXV4g30AvmQRawYK/images/20231123/d9e7e465ed1e369ee8a49cfb048ad45f.jpg"
        class_info: "2020届 初三（1） 东校区（班主任：王小明）"
        id: 225
        is_recommend: 0
        join_organization_id: 1
        nickname: "广州和其正凉茶12345678"
        phone: "13570432706"
        position_id: 42
        position_name: "首席执行主席"
        status: 1
        title_name: "广州茂青会|首席执行主席"
        user_id: 225
        work_place: ""
        work_role: ""
       }]
       */
      this.selectedUsers = dataList.map((d) => ({
        ...d,
        progress: 0, // 转移进度
        status: -1, // 1：是成功，-1：是未知，初始状态
        errMsg: '', // 错误信息
      }))
      this.saveLoading = false
      return this.selectedUsers
    },
    submit() {
      if (this.selectedUsers.filter((s) => s.status !== -1).length > 0) {
        // 有部分上传成功了，就当是处理完了，此时可关闭弹窗
        this.openDialog = false
        return
      }

      if ((this.all === 1 || this.selectedUsers.length) && !this.transferForm) {
        // 全员转移，跳过选会员步骤
        this.getPositionUsers()
        this.openPosition = true
      } else if (this.selectedUsers.length === 0) {
        this.select()
      }
    },
    getPositionUsers() {
      if (this.saveLoading) return
      this.saveLoading = true
      this.$request('/admin/cyc/position/users', {
        id: this.ID,
        user_name: '',
      })
        .then(({ data, msg }) => {
          this.users = data
          this.saveLoading = false
        })
        .catch(() => {
          this.saveLoading = false
        })
    },
    doTransfer() {
      this.clickTransferButton = true

      if (!this.selectedUsers.length && this.all === 1) {
        this.onSelect(this.users)
      }

      const arr = this.selectedUsers

      this.saveLoading = true

      manager = new AQM({ maxParallel: 5 })

      arr.forEach((data) => {
        try {
          manager.push(() => {
            return new Promise((resolve) => {
              this.$request(
                '/admin/cyc/user_op/changeUser',
                {
                  ...this.transferForm,
                  id: data.user_position_id,
                },
                {
                  notCancel: true,
                }
              )
                .then((res) => {
                  data.status = res.code
                  data.errMsg = res.msg
                  if (res.code === 1) data.progress = 100
                  resolve(data)
                })
                .catch((err) => {
                  data.status = 0
                  console.log(err, 'rrrrrrrrrrrr')
                  data.errMsg = err.msg || '出错了'
                  resolve(data)
                })
            })
          })
        } catch (e) {
          console.log(e)
        }
      })

      let resultList = []
      manager
        .start(function (res) {
          // 每一次请求成功的回调
          resultList.push(res)
        })
        .then(() => {
          console.log(resultList)
          this.saveLoading = false
        })
        .catch((err) => {
          // console.log(err)
          this.loading = false
        })
    },
    transfer(form) {
      if (form) this.transferForm = form
      // if (!this.selectedUsers.length && this.all === 1) {
      //   this.onSelect(this.users)
      // }

      if (form) {
        this.doTransfer()
      }
    },
  },
}
</script>

<style lang="scss">
.dialog-vertical {
  .btn {
    display: flex;
    justify-content: center;

    p {
      line-height: 1.7;
    }

    .el-button {
      width: 238px;
      height: 292px;
    }
  }
}

.user-progress {
  min-height: 30px;
  position: relative;
  line-height: 30px;
  background-color: #f0f0f0;
  border-radius: 4px;
  padding: 0 8px;

  & + .user-progress {
    margin-top: 12px;
  }

  /*  &:before {
    content: '';
    background-color: $success;
    width: var(--progress-number);
    height: 100%;
    transition: width 0.3s;
    position: absolute;
  }*/

  /*&:after {*/
  /*  content: attr(data-progress);*/
  /*  display: block;*/
  /*}*/
}
</style>
