<template>
  <el-dialog
    title="选择会员"
    :visible.sync="openDialog"
    width="600px"
    :show-close="false"
    class="dialog-vertical"
  >
    <div
      class="head-filter"
      :style="'width: 50%'"
    >
      <el-input
        v-model="filterForm.user_name"
        placeholder="请输入会员名称"
        @change="handleFilter"
      >
        <i class="el-icon-search el-input__icon" slot="suffix"></i>
      </el-input>
    </div>
    <div class="member-list" style="margin-top: 35px">
      <el-table
        :data="memberList"
        style="width: 100%"
        :height="350"
        ref="multipleTable"
        :row-key="rowKey"
        :highlight-current-row="!selectType"
        @row-click="handleCurrentChange"
        @selection-change="handleSelectionChange"
        v-loading="loading"
      >
        <!-- 多选 -->
        <el-table-column
          ref="selection"
          fixed
          type="selection"
          width="70"
          :selectable="selectable"
          v-if="selectType"
          :key="Math.random()"
        ></el-table-column>
        <!-- 单选 -->
        <el-table-column
          fixed
          width="70"
          v-if="!selectType"
          :key="Math.random()"
        >
          <template slot-scope="scope">
            <el-radio
              :label="scope.row"
              v-model="currentRow"
              :disabled="
                disableMembers.length
                  ? disableMembers.findIndex(
                      (item) => item[rowKey] === scope.row[rowKey]
                    ) !== -1
                  : selectedMembers.findIndex(
                      (item) => item[rowKey] === scope.row[rowKey]
                    ) !== -1
              "
            >
              {{ '&nbsp' }}</el-radio
            >
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column
            :prop="th.prop"
            :label="th.label"
            :width="th.width || ''"
            :key="index"
          >
            <template slot-scope="{ row }">
              <el-avatar v-if="th.prop === 'avatar'" :src="fixImageUrl(row[th.prop] || defaultAvatar, 300)"></el-avatar>
              <p v-else style="white-space: pre-wrap">
                {{ row[th.prop] | placeholder }}
              </p>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <template #footer>
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce'
import {randomString} from '../../../../base/utils/tool'

export default {
  name: 'transfer-user-selector',
  model: {
    prop: 'open',
    event: 'closeDialog',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    // 已选择会员数据
    Members: {
      type: Array,
      default: () => [],
    },
    // 限制选择的会员数据
    disableMembers: {
      type: Array,
      default: () => [],
    },
    // 0:单选/1:多选模式
    selectType: {
      type: Number,
      default: 1,
    },
    //点击确定后返回数据形式:适用多选(total: 已选中和弹窗选中会员总和/check：弹窗当前选中会员)
    selectedType: {
      type: String,
      default: 'total',
    },
    // 当前选中索引
    currentIndex: {
      type: Number,
      default: 0,
    },
    // 选择数量限制
    limit: {
      type: Number,
      default: 99,
    },
    hash: {
      type: Boolean,
      default: false,
    },
    rowKey: String,
    positionId: [String, Number] // 传了这个就不能修改职务
  },
  data() {
    const thead = [
      { label: '头像', prop: 'avatar'},
      { label: '会员名称', prop: 'name' },
    ]
    return {
      defaultAvatar: window.serverConfig.VUE_APP_ADMINURL + "/cyc/images/wxapps/icons/avatar1.png",
      loading: false, //加载
      thead,
      memberList: [],
      //筛选对象
      filterForm: {
        id: '', // 职务id
        user_name: '', //关键词
      },
      getable: true, //是否可以请求列表
      currentRow: '', // 单选时选中的数据项
      selectArr: [], //勾选的数组id
    }
  },
  methods: {
    // 获取会员列表
    getSearchMemberList: debounce(function () {
      if (this.getable) {
        this.loading = true
        // if (this.positionId !== undefined) {
        //   this.filterForm.id = this.positionId
        // }
        this.$request('/admin/cyc/position/users', {
          ...this.filterForm
        })
          .then((res) => {
            const { data } = res
            this.memberList = data.map(d => ({...d, id: randomString(8)}))
            this.loading = false
          })
          .catch((err) => {})
      }
    }, 200),
    // 判断是否可选
    selectable(row, index) {
      let disableMembers = this.disableMembers.length
        ? this.disableMembers
        : this.selectedMembers
      if (
        disableMembers.findIndex((item) => item[this.rowKey] === row[this.rowKey]) !== -1
      ) {
        return false
      } else {
        return true
      }
    },
    // 筛选操作
    handleFilter(e, key) {
      const fliterData = { ...this.filterForm }
      this.getable = true
      this.getSearchMemberList(fliterData)
    },
    // 勾选操作
    handleSelectionChange(selection) {
      console.log(selection, 'selection???')
      if (!selection.length) {
        this.selectArr = []
      } else {
        if (selection.length > this.limit) {
          this.selectArr = selection.slice(0, this.limit)
          const leftArr = selection.slice(this.limit)
          this.$message.error(`最多可选择 ${this.limit} 个会员`)
          leftArr.forEach((el) => {
            this.$refs.multipleTable.toggleRowSelection(el, false)
          })
        } else {
          this.selectArr = [...selection]
        }
      }
    },
    // 点击数据项操作
    handleCurrentChange(val) {
      console.log(val, 'vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvs')
      console.log(this.selectType, 'selectType')
      const {rowKey} = this
      if (!this.selectType) {
        let disabled
        if (this.disableMembers.length) {
          disabled =
            this.disableMembers.findIndex(
              (item) => item[rowKey] === val[rowKey]
            ) !== -1
        } else {
          disabled =
            this.selectedMembers.findIndex(
              (item) => item[rowKey] === val[rowKey]
            ) !== -1
        }

        if (!disabled) this.currentRow = val
      } else {
        // 多选哦
        if (
          this.selectedMembers.findIndex(
            (item) => item[rowKey] === val[rowKey]
          ) !== -1
        ) {
          return
        } else {
          this.memberList.forEach((element) => {
            if (val[rowKey] === element[rowKey]) {
              this.$nextTick(() => {
                this.$refs.multipleTable.toggleRowSelection(element)
              })
            }
          })
        }
      }
    },
    //触底刷新
    LoadMore() {
      if (this.getable) {
        // this.handleFilter(this.filterForm, 'Load')
      }
    },
    // 确定
    submit() {
      if (this.selectType) {
        if (!this.selectArr.length) {
          this.$message.info('请选择数据项')
        } else {
          const arr =
            this.selectedType === 'total'
              ? [...this.selectedMembers, ...this.selectArr]
              : [...this.selectArr]
          this.selectedMembers = arr
          this.$refs.multipleTable.clearSelection()
          this.selectArr = []
          this.openDialog = false
        }
      } else {
        if (!this.currentRow) {
          this.$message.info('请选择数据项')
        } else {
          this.selectedMembers.splice(this.currentIndex, 1, this.currentRow)
          this.$emit('changeSelect', this.selectedMembers)
          this.openDialog = false
        }
      }
    },
    // 取消
    cancel() {
      this.openDialog = false
    },
  },
  watch: {
    openDialog(val) {
      if (val) {
        if (this.positionId !== undefined) {
          this.filterForm.id = this.positionId
        }
        const data = { ...this.filterForm }
        this.getSearchMemberList(data)
      } else {
        this.$refs.multipleTable.clearSelection()
        this.selectArr = []
        this.memberList = []
      }
    },
    memberList: {
      deep: true,
      handler(val) {
        this.memberList.forEach((el) => {
          if (
            this.selectArr.findIndex((elem) => elem[this.rowKey] === el[this.rowKey]) !==
              -1 &&
            this.$refs.multipleTable
          ) {
            this.$nextTick(() => {
              this.$refs.multipleTable.toggleRowSelection(el, true)
            })
          }
        })
      },
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('closeDialog', val)
      },
    },
    selectedMembers: {
      get() {
        return this.Members
      },
      set(val) {
        this.$emit('changeSelect', val)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.check-post {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.head-filter {
  // width: 80%;
  display: flex;
  position: absolute;
  top: 40px;
  right: 40px;
  justify-content: flex-end;

  .el-select,
  .el-cascader,
  .el-input {
    flex: 1;
    margin-right: 10px;
  }
}

.member-list {
  ::v-deep.el-table__row {
    cursor: pointer;
  }
}
</style>
