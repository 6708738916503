var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"dialog-vertical",attrs:{"title":_vm.postForm.id ? '编辑会内职务' : '新增会内职务',"visible":_vm.openDialog,"width":"600px","show-close":true},on:{"update:visible":function($event){_vm.openDialog=$event},"close":function($event){return _vm.cancel('postForm')}}},[_c('el-form',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isNext),expression:"!isNext"},{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"postForm",attrs:{"size":"medium","model":_vm.postForm,"rules":_vm.rules,"label-width":"110px"},nativeOn:{"submit":function($event){$event.preventDefault();}}},[_c('el-form-item',{attrs:{"label":"职务名称：","prop":"name"}},[_c('el-input',{staticStyle:{"width":"75%"},attrs:{"type":"text","placeholder":"请输入分类名称"},model:{value:(_vm.postForm.name),callback:function ($$v) {_vm.$set(_vm.postForm, "name", $$v)},expression:"postForm.name"}}),_c('div',{staticClass:"form-tip"},[_c('span',{staticClass:"el-icon-info",staticStyle:{"font-size":"15px","color":"#c0c4cc"}}),_c('span',[_vm._v("不允许填写 \"预备会员\" 名称")])])],1),_c('el-form-item',{ref:"idsFormItem",attrs:{"label":_vm.tenantVersion === 'school' ? '所属校友会：' : '所属架构：',"prop":"ids"}},[(!_vm.postForm.id)?_c('el-cascader',{ref:"addCascader",staticStyle:{"width":"75%"},attrs:{"value":_vm.postForm.ids,"options":_vm.parentArr,"collapse-tags":false,"props":{
            value: 'id',
            label: 'name',
            checkStrictly: true,
            expandTrigger: 'hover',
            multiple: true,
          },"placeholder":_vm.postForm.id
              ? _vm.postForm.pname
              : _vm.tenantVersion === 'school'
              ? '请选择'
              : '请选择所属架构',"filterable":""},on:{"change":_vm.handleExportCatalogChange,"visible-change":_vm.onVisibleChange}}):_c('el-cascader',{key:_vm.ID,ref:"editCascader",staticStyle:{"width":"75%"},attrs:{"options":_vm.parentArr,"collapse-tags":"","props":{
            value: 'id',
            label: 'name',
            checkStrictly: true,
            expandTrigger: 'hover',
          },"placeholder":_vm.postForm.id
              ? _vm.postForm.pname
              : _vm.tenantVersion === 'school'
              ? '请选择'
              : '请选择所属架构',"filterable":""},on:{"change":_vm.handleExportCatalogChange},model:{value:(_vm.postForm.ids),callback:function ($$v) {_vm.$set(_vm.postForm, "ids", $$v)},expression:"postForm.ids"}}),(false)?_c('div',{staticClass:"flex-col",staticStyle:{"width":"75%"}},_vm._l((_vm.postForm.ids),function(user,userI){return _c('span',{staticClass:"user-progress flex-between"},[_vm._v(_vm._s(_vm.getTitleName(user))+" "),(!_vm.saveLoading)?_c('i',{staticClass:"el-icon-close",on:{"click":function($event){return _vm.postForm.ids.splice(userI, 1)}}}):_vm._e()])}),0):_vm._e()],1),_c('el-form-item',{attrs:{"label":"会费/元：","prop":"fee"}},[_c('el-input',{staticClass:"fee-input",attrs:{"type":"number","placeholder":"请输入会费"},model:{value:(_vm.postForm.fee),callback:function ($$v) {_vm.$set(_vm.postForm, "fee", _vm._n($$v))},expression:"postForm.fee"}}),_c('div',{staticClass:"form-tip"},[_c('span',{staticClass:"el-icon-info",staticStyle:{"font-size":"15px","color":"#c0c4cc"}}),_c('span',[_vm._v("设为 0 元时无需缴纳会费")])])],1),_c('el-form-item',{attrs:{"label":"权限配置："}},_vm._l((_vm.settingConfigArr),function(item,index){return _c('el-checkbox',{key:index,staticStyle:{"margin-right":"15px"},attrs:{"label":item.name,"false-label":0,"true-label":1},model:{value:(_vm.postForm[item.key]),callback:function ($$v) {_vm.$set(_vm.postForm, item.key, $$v)},expression:"postForm[item.key]"}})}),1)],1),(_vm.isNext)?[_c('div',{staticClass:"flex-col",staticStyle:{"width":"100%"}},_vm._l((_vm.selectedUsers),function(user,userI){return _c('span',{staticClass:"user-progress flex-between",style:({
            '--progress-number': user.progress + '%',
          }),attrs:{"data-progress":user.progress + '%'}},[_vm._v(_vm._s(user.positionList.map(function (p) { return p.name; }).join('/') + '-' + _vm.postForm.name)),(user.errMsg)?_c('span',{staticStyle:{"white-space":"nowrap"},style:({ color: user.status === 1 ? '#13CE66' : 'red' })},[_vm._v("【"+_vm._s(user.errMsg)+"】")]):(_vm.saveLoading && user.status === -1)?_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("【处理中...】")]):_vm._e(),(!_vm.saveLoading && !user.errMsg)?_c('i',{staticClass:"el-icon-close",on:{"click":function($event){return _vm.onClickDelete(user, userI)}}}):_vm._e()])}),0)]:_vm._e(),_c('div',{staticStyle:{"width":"100%","height":"100px"}}),_c('span',{attrs:{"slot":"footer"},slot:"footer"},[(!_vm.saveLoading)?_c('el-button',{on:{"click":function($event){_vm.openDialog = false}}},[_vm._v(_vm._s(_vm.selectedUsers.filter(function (s) { return s.status !== -1; }).length === 0 ? '取消' : '关闭'))]):_vm._e(),(_vm.isNext && !_vm.selectedUsers.filter(function (s) { return s.status !== -1; }).length)?_c('el-button',{attrs:{"type":"primary","loading":_vm.saveLoading},on:{"click":function($event){_vm.isNext = false}}},[_vm._v("上一步")]):_vm._e(),(_vm.isNext && !_vm.selectedUsers.filter(function (s) { return s.status !== -1; }).length)?_c('el-button',{attrs:{"type":"primary","loading":_vm.saveLoading},on:{"click":function($event){return _vm.submit('postForm')}}},[_vm._v("批量新增")]):(
          !_vm.isNext && !_vm.postForm.id && _vm.postForm.ids && _vm.postForm.ids.length > 1
        )?_c('el-button',{attrs:{"type":"primary","loading":_vm.saveLoading},on:{"click":function($event){return _vm.next()}}},[_vm._v("下一步")]):(!_vm.isNext)?_c('el-button',{attrs:{"type":"primary","loading":_vm.saveLoading},on:{"click":function($event){return _vm.submit('postForm')}}},[_vm._v("确定")]):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }