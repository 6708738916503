<template>
  <div class="list-page">
    <el-button class="top-btn" type="primary" size="small" @click="handleAddPost">新增</el-button>
    <list-filter v-model="filterForm" :levelList="LevelList" :ids="selectArr" :page="pageData.current_page"
      :uploadFilter="ok" />
    <div class="list-content" style="margin-top: 20px" v-loading="loading">
      <div class="list-btn">
        <el-button size="small" type="danger" @click="handleDel">删除</el-button>
      </div>
      <el-table :data="tbody" class="thead-light" stripe style="width: 100%"
                :default-sort="{prop: filterForm.order_by, order: filterForm.is_desc ? 'descending' : 'ascending'}"
        @selection-change="handleSelectionChange" @sort-change="sortChange">
        <!-- 勾选 -->
        <el-table-column class="select-check" fixed type="selection" width="55" :selectable="selectable">
        </el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="EditRow(scope.row)"
              :disabled="scope.row.name === '预备会员'">
              编辑
            </el-button>
            <el-button type="text" size="small" @click="CopyRow(scope.row)">
              复制
            </el-button>
            <el-divider direction="vertical" />
            <el-dropdown @command="handleClickRow($event, scope.row)">
              <span class="el-dropdown-link">更多</span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="transformRow">转移</el-dropdown-item>
                <el-dropdown-item command="configRow" v-if="scope.row.name !== '预备会员'">权限</el-dropdown-item>
                <el-dropdown-item command="deleteRow" v-if="scope.row.name !== '预备会员'">删除</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <template v-for="(th, index) in settingThead">
          <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
            :min-width="th.minWidth || ''" :sortable="th.sortable" :key="index">
            <template slot-scope="scope">
              <!-- 普通 -->
              <span>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
    <AddPost v-model="openAddDialog" :ID="currentId" :isCopy="isCopy" :updateList="reflashList" />
<!--    <TransformPost v-model="openTransformPost" :ID="currentId" />-->
    <position-users-transfer v-model="openTransformPost" :ID="currentId" :OID="currentOrganizationId" :updateList="reflashList" />
    <PostConfig v-model="openConfig" :ID="currentId" :updateList="reflashList" />
  </div>
</template>

<script>
import listFilter from "../../components/MeetingPost/ListFilter";
import Pagination from "@/base/components/Default/Pagination";
import {
  postList,
  Delete,
  parentToList,
} from "../../api/meeting-post/post-list";
import AddPost from "../../components/MeetingPost/AddPost";
import TransformPost from "../../components/MeetingPost/TransformPost";
import PostConfig from "../../components/MeetingPost/PostConfig";
import PositionUsersTransfer from '../../components/MeetingPost/position-users-transfer'
export default {
  data() {
    return {
      loading: false, //加载
      thead: [
        { label: "ID", prop: "id" },
        { label: "职务名称", prop: "name", minWidth: 120 },
        { label: "所属架构", prop: "organization_name", minWidth: 120 },
        { label: "会费/元", prop: "fee", minWidth: 100, sortable: true },
        // { label: "职务费/元", prop: "position_fee", minWidth: 110 },
        {
          label: "会员人数",
          prop: "members_count",
          minWidth: 100,
          sortable: 'custom',
        },
      ],
      schoolThead: [
        { label: "ID", prop: "id" },
        { label: "职务名称", prop: "name", minWidth: 120 },
        { label: "所属校友会", prop: "organization_name", minWidth: 120 },
        { label: "会费/元", prop: "fee", minWidth: 100, sortable: 'custom' },
        // { label: "职务费/元", prop: "position_fee", minWidth: 110 },
        {
          label: "校友人数",
          prop: "members_count",
          minWidth: 100,
          sortable: true,
        },
      ],
      //筛选对象
      filterForm: {
        organization_id: -1, // 所属架构Id
        keyword: "", //架构名称
        page_size: 15, //每页数据数
      },
      LevelList: [], // 架构层级
      pageData: {
        page_size: 15,
      },
      currentId: -1, //列表操作当前id
      currentOrganizationId: -1, // 操作的架构id
      isCopy: false,
      tbody: [],
      selectArr: [], //勾选的数组id
      openAddDialog: false,
      openTransformPost: false,
      openConfig: false,
    };
  },
  methods: {
    // 获取列表
    getPostList(pageData) {
      this.loading = true;
      postList(pageData)
        .then((res) => {
          this.getParentToList();
          const { data } = res;
          this.tbody = data.data;
          console.log(this.tbody);
          this.pageData = data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 获取所属组织列表
    getParentToList() {
      parentToList({ crr_level: -1 })
        .then((res) => {
          this.LevelList = res.data;
        })
        .catch((err) => {});
    },
    // 判断是否可选
    selectable(row, index) {
      return row.name !== "预备会员";
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id);
        });
      }
    },
    // 新增职务
    handleAddPost() {
      this.currentId = 0;
      this.currentOrganizationId = -1;
      this.openAddDialog = true;
    },
    // 批量删除
    handleDel() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm("你确定要删除选中数据吗？删除后无法恢复。", "提示", {
            type: "error",
          })
          .then((res) => {
            this.handleDelete({ id: this.selectArr });
          })
          .catch((err) => {});
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击下拉菜单
    handleClickRow(value, row) {
      this[value](row);
    },
    // 点击列表删除
    deleteRow(row) {
      this.$msgbox
        .confirm("你确定要删除该数据吗？删除后无法恢复。", "提示", {
          type: "error",
        })
        .then((res) => {
          this.handleDelete({ id: row.id });
        })
        .catch((err) => {});
    },
    // 数据删除
    handleDelete(id) {
      this.loading = true;
      Delete(id)
        .then((res) => {
          this.$message.success(res.msg);
          this.reflashList();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 点击编辑
    EditRow(row) {
      this.isCopy = false;
      this.currentId = row.id;
      this.openAddDialog = true;
    },
    // 复制
    CopyRow(row) {
      this.isCopy = true;
      this.currentId = row.id;
      this.openAddDialog = true;
    },
    //点击转移
    transformRow(row) {
      if (row.members_count > 0) {
        this.currentId = row.id;
        this.currentOrganizationId = row.organization_id;
        this.openTransformPost = true;
      } else {
        this.$msgbox
          .confirm("该职务没有会员人数，无需进行转移操作", "提示", {
            showCancelButton: false,
          })
          .then((res) => {});
      }
    },
    // 点击权限
    configRow(row) {
      this.currentId = row.id;
      this.openConfig = true;
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getPostList(this.filterForm);
    },
    // 筛选回调
    ok(e) {
      const pageData = {};
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getPostList({ ...this.filterForm, ...pageData });
    },
    // 刷新列表
    reflashList() {
      this.getPostList({
        page: this.pageData.current_page,
        ...this.filterForm,
      });
      this.currentId = 0;
    },
    // 分页查询
    changPage(e) {
      console.log(e);
      this.filterForm.page_size = e.page_size;
      const pageData = { ...this.filterForm, ...e };
      this.getPostList(pageData);
    },
  },
  computed: {
    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
    settingThead() {
      return this.tenantVersion === 'school' ? this.schoolThead:this.thead
    },
  },
  created() {
    this.getPostList(this.pageData); //获取列表数据
  },
  components: {PositionUsersTransfer, listFilter, Pagination, AddPost, TransformPost, PostConfig },
};
</script>

<style lang="scss" scoped>
.container {
  .body {
    .tool-tip {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .el-dropdown-link {
      color: #3576ff;
      cursor: pointer;
      font-size: 12px;
    }
  }
}
</style>