<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :collapseHeight="60" :toggleCollapseEnable="false"
    :onExport="onExport">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from">
      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="职务名称：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入架构名称"></el-input>
        </el-form-item>
        <el-form-item :label="tenantVersion === 'school' ? '所属校友会：':'所属架构：'" prop="organization_id">
          <el-select filterable clearable style="margin-left: 15px;" v-model="organization_id" :placeholder="tenantVersion === 'school' ? '请选择':'请选择所属架构'">
            <el-option v-for="item in organization_list" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import { documentPositionList } from "../../api/meeting-post/post-list";
export default {
  model: {
    prop: "filterForm",
    event: "updateFilter",
  },
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    levelList: {
      type: [Array, Object],
    },
    filterForm: {
      type: Object,
    },
    ids: {
      type: Array,
    },
    page: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      rules: {},
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
    // 导出会内职务列表
    onExport() {
      const postData = { ...this.form, ids: this.ids, page: this.page };
      return documentPositionList(postData).catch(() => {});
    },
  },
  created() {},
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {
        this.$emit("updateFilter", val);
      },
    },
    organization_id: {
      get() {
        return this.form.organization_id == -1 ? "" : this.form.organization_id;
      },
      set(val) {
        this.form.organization_id = val;
      },
    },
    organization_list() {
      return this.levelList;
    },
    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
  },
  components: {
    LayoutFilter,
  },
};
</script>