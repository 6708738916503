var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{staticClass:"dialog-vertical",attrs:{"title":"修改组织架构 | 会内职务","visible":_vm.openDialog,"width":"500px","show-close":true},on:{"update:visible":function($event){_vm.openDialog=$event}}},[_c('el-form',{ref:"transFormData",staticClass:"small-form",attrs:{"size":"medium","model":_vm.transFormData,"rules":_vm.rules,"label-position":"right","label-width":"90px"}},[_c('el-form-item',{attrs:{"label":'组织架构：',"prop":"ids"}},[_c('el-cascader',{ref:"CascaderRef",staticStyle:{"width":"80%"},attrs:{"options":_vm.parentArr,"props":{
          value: 'id',
          label: 'name',
          checkStrictly: true,
          expandTrigger: 'hover',
          emitPath: true,
        },"placeholder":"请选择"},on:{"change":_vm.handleSelect},model:{value:(_vm.transFormData.ids),callback:function ($$v) {_vm.$set(_vm.transFormData, "ids", $$v)},expression:"transFormData.ids"}})],1),_c('el-form-item',{attrs:{"label":"会内职务：","prop":"position_id"}},[_c('el-select',{staticStyle:{"width":"80%"},attrs:{"placeholder":!_vm.transFormData.ids.length
            ? '请先选择组织架构'
            : _vm.loadingPosition
            ? '职务加载中...'
            : '请选择会内职务',"disabled":!_vm.transFormData.ids.length || _vm.loadingPosition},model:{value:(_vm.transFormData.position_id),callback:function ($$v) {_vm.$set(_vm.transFormData, "position_id", $$v)},expression:"transFormData.position_id"}},_vm._l((_vm.postList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1),_c('el-form-item',{attrs:{"label":"会费状态："}},[_c('el-radio-group',{model:{value:(_vm.transFormData.pay_status),callback:function ($$v) {_vm.$set(_vm.transFormData, "pay_status", $$v)},expression:"transFormData.pay_status"}},_vm._l((_vm.payStatusList),function(pay){return _c('el-radio',{key:pay.id,attrs:{"label":pay.id}},[_vm._v(_vm._s(pay.name))])}),1),_c('p',{staticClass:"info mTop"},[_c('i',{staticClass:"icon el-icon-info"}),_vm._v("缴费状态关系到会员是否需要重新缴纳更改后的会费 ")])],1)],1),_c('span',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.saveLoading,"disabled":_vm.loadingPosition},on:{"click":function($event){return _vm.submit('transFormData')}}},[_vm._v("转移")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }