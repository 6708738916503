import api from "@/base/utils/request";

// 获取权限
export const seePermission = data => {
  return api({
    url: "/admin/cyc/position/seePermission",
    method: "post",
    data
  });
};

// 保存权限接口
export const savePermission = data => {
  return api({
    url: "/admin/cyc/position/savePermission",
    method: "post",
    data
  });
};