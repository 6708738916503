<template>
  <el-dialog title="权限配置" :visible.sync="openDialog" width="500px" :show-close="true" class="dialog-vertical">
    <div class="box-group" v-loading="loading">
      <el-checkbox style="margin-left: 40px; margin-top: 10px" v-for="(item, index) in settingConfigArr"
        :label="item.name" :key="index" v-model="configData[item.key]" :true-label="1" :false-label="0">
      </el-checkbox>
    </div>
    <span slot="footer">
      <el-button @click="openDialog = false">取消</el-button>
      <el-button type="primary" @click="submit('transFormData')" :loading="saveLoading">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  seePermission,
  savePermission,
} from "../../api/meeting-post/config-post";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    ID: {
      type: [Number, String],
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      loading: false,
      configData: {
        selectable_on_join: 0,
        // no_need_to_pay: false,
        join_activity_unlimit: 0,
        no_need_to_write_activity_exp: 0,
      },
      // 权限配置
      configArr: [
        { name: "入会申请时可选择", key: "selectable_on_join" },
        // { name: "无需进行会费缴纳", key: "no_need_to_pay" },
        { name: "活动报名不受限制", key: "join_activity_unlimit" },
        { name: "活动无需填写心得", key: "no_need_to_write_activity_exp" },
      ],
      // 学校权限配置
      schoolConfigArr: [
        { name: "认证申请时可选择", key: "selectable_on_join" },
        { name: "活动报名不受限制", key: "join_activity_unlimit" },
        { name: "活动无需填写心得", key: "no_need_to_write_activity_exp" },
      ],
      saveLoading: false,
    };
  },
  methods: {
    // 获取权限详情
    getSeePermission(id) {
      this.loading = true;
      seePermission(id)
        .then((res) => {
          const { data } = res;
          this.configData = data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 保存
    submit(formName) {
      const data = { ...this.configData };
      this.saveLoading = true;
      savePermission(data)
        .then((res) => {
          this.$message.success("配置成功");
          this.updateList();
          this.saveLoading = false;
          this.openDialog = false;
        })
        .catch((err) => {
          this.saveLoading = false;
        });
    },
  },
  watch: {
    ID(val) {
      if (val) {
        this.getSeePermission({ id: val });
      }
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
    // 设置版本权限配置
    settingConfigArr() {
      return this.tenantVersion === 'school' ? this.schoolConfigArr : this.configArr;
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
