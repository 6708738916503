<template>
  <el-dialog
    :title="postForm.id ? '编辑会内职务' : '新增会内职务'"
    :visible.sync="openDialog"
    width="600px"
    :show-close="true"
    class="dialog-vertical"
    @close="cancel('postForm')"
  >
    <el-form
      v-show="!isNext"
      size="medium"
      :model="postForm"
      :rules="rules"
      ref="postForm"
      label-width="110px"
      v-loading="loading"
      @submit.native.prevent
    >
      <el-form-item label="职务名称：" prop="name">
        <el-input
          type="text"
          style="width: 75%"
          v-model="postForm.name"
          placeholder="请输入分类名称"
        ></el-input>
        <div class="form-tip">
          <span
            class="el-icon-info"
            style="font-size: 15px; color: #c0c4cc"
          ></span>
          <span>不允许填写 "预备会员" 名称</span>
        </div>
      </el-form-item>
      <el-form-item
        ref="idsFormItem"
        :label="tenantVersion === 'school' ? '所属校友会：' : '所属架构：'"
        prop="ids"
      >
<!--        <p>{{ postForm.ids }}</p>-->
        <!-- 新增、多选 -->
        <!-- v-model 有点问题：选完一个就会自动关闭面板 -->
        <el-cascader
          v-if="!postForm.id"
          ref="addCascader"
          style="width: 75%"
          :value="postForm.ids"
          :options="parentArr"
          @change="handleExportCatalogChange"
          :collapse-tags="false"
          @visible-change="onVisibleChange"
          :props="{
            value: 'id',
            label: 'name',
            checkStrictly: true,
            expandTrigger: 'hover',
            multiple: true,
          }"
          :placeholder="
            postForm.id
              ? postForm.pname
              : tenantVersion === 'school'
              ? '请选择'
              : '请选择所属架构'
          "
          filterable
        >
          <!-- 所属架构 -->
        </el-cascader>
        <!-- 编辑、单选 -->
        <el-cascader
          v-else
          ref="editCascader"
          style="width: 75%"
          v-model="postForm.ids"
          :options="parentArr"
          @change="handleExportCatalogChange"
          collapse-tags
          :props="{
            value: 'id',
            label: 'name',
            checkStrictly: true,
            expandTrigger: 'hover',
          }"
          :placeholder="
            postForm.id
              ? postForm.pname
              : tenantVersion === 'school'
              ? '请选择'
              : '请选择所属架构'
          "
          filterable
          :key="ID"
        >
          <!-- 所属架构 -->
        </el-cascader>

      <div v-if="false" class="flex-col" style="width: 75%">
        <span
          class="user-progress flex-between"
          v-for="(user, userI) in postForm.ids"
          >{{ getTitleName(user)
          }}
          <i
            v-if="!saveLoading"
            @click="postForm.ids.splice(userI, 1)"
            class="el-icon-close"
          ></i>
        </span>
      </div>
      </el-form-item>
      <el-form-item label="会费/元：" prop="fee">
        <el-input
          type="number"
          class="fee-input"
          v-model.number="postForm.fee"
          placeholder="请输入会费"
        >
        </el-input>
        <div class="form-tip">
          <span
            class="el-icon-info"
            style="font-size: 15px; color: #c0c4cc"
          ></span>
          <span>设为 0 元时无需缴纳会费</span>
        </div>
      </el-form-item>
      <!-- <el-form-item label="职务费/元：" prop="position_fee">
        <el-input type="number" class="fee-input" v-model.number="postForm.position_fee"
          placeholder="请输入职务费">
        </el-input>
      </el-form-item> -->
      <el-form-item label="权限配置：">
        <el-checkbox
          style="margin-right: 15px"
          v-for="(item, index) in settingConfigArr"
          :label="item.name"
          :key="index"
          v-model="postForm[item.key]"
          :false-label="0"
          :true-label="1"
        ></el-checkbox>
      </el-form-item>
    </el-form>
    <template v-if="isNext">
      <!--      <p>{{ postForm.ids }}</p>-->
      <div class="flex-col" style="width: 100%">
        <span
          class="user-progress flex-between"
          :data-progress="user.progress + '%'"
          :style="{
            '--progress-number': user.progress + '%',
          }"
          v-for="(user, userI) in selectedUsers"
          >{{
            user.positionList.map((p) => p.name).join('/') +
            '-' +
            postForm.name
          }}<span
            v-if="user.errMsg"
            style="white-space: nowrap"
            :style="{ color: user.status === 1 ? '#13CE66' : 'red' }"
            >【{{ user.errMsg }}】</span
          >
          <span
            style="white-space: nowrap"
            v-else-if="saveLoading && user.status === -1"
            >【处理中...】</span
          >
          <i
            v-if="!saveLoading && !user.errMsg"
            @click="onClickDelete(user, userI)"
            class="el-icon-close"
          ></i>
        </span>
      </div>
    </template>
    <div style="width: 100%; height: 100px;"><!-- 加高 --></div>
    <span slot="footer">
      <el-button v-if="!saveLoading" @click="openDialog = false">{{
        selectedUsers.filter((s) => s.status !== -1).length === 0
          ? '取消'
          : '关闭'
      }}</el-button>

      <el-button
        v-if="isNext && !selectedUsers.filter((s) => s.status !== -1).length"
        type="primary"
        @click="isNext = false"
        :loading="saveLoading"
        >上一步</el-button
      >

      <!--      <el-button @click="openDialog = false">取消</el-button>-->
      <el-button
        v-if="isNext && !selectedUsers.filter((s) => s.status !== -1).length"
        type="primary"
        @click="submit('postForm')"
        :loading="saveLoading"
        >批量新增</el-button
      >

      <el-button
        v-else-if="
          !isNext && !postForm.id && postForm.ids && postForm.ids.length > 1
        "
        type="primary"
        @click="next()"
        :loading="saveLoading"
        >下一步</el-button
      >

      <el-button
        v-else-if="!isNext"
        type="primary"
        @click="submit('postForm')"
        :loading="saveLoading"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import {
  positionDetail,
  ParentList,
  SavePosition,
} from '../../api/meeting-post/add-post'

import {isArray, randomString} from '@/base/utils/tool'

import AQM from '@/base/utils/async-queue-manager'

//异步队列管理
var manager = null

export default {
  model: {
    prop: 'open',
    event: 'closeDialog',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    ID: {
      type: [Number, String],
    },
    // 是否为复制模式
    isCopy: {
      type: Boolean,
      default: false,
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      loading: false,
      postForm: {
        id: 0,
        join_organization_id: 0,
        organization_id: 0,
        name: '',
        fee: 0,
        position_fee: 0,
        selectable_on_join: 0,
        join_activity_unlimit: 0,
        // no_need_to_write_activity_exp: 0,
        ids: [], // 选择所属组织回调参数
      },
      parentArr: [], //所属架构列表
      // 权限配置
      configArr: [
        { name: '入会申请时可选择', key: 'selectable_on_join' },
        { name: '活动报名不受限制', key: 'join_activity_unlimit' },
        // { name: '活动无需填写心得', key: 'no_need_to_write_activity_exp' },
      ],
      // 校友权限配置
      schoolConfigArr: [
        { name: '认证申请时可选择', key: 'selectable_on_join' },
        { name: '活动报名不受限制', key: 'join_activity_unlimit' },
        // { name: '活动无需填写心得', key: 'no_need_to_write_activity_exp' },
      ],
      rules: {
        name: [
          { required: true, message: '请输入职务名称', trigger: 'blur' },
          { required: true, validator: this.namePass, trigger: 'blur' },
        ],
        ids: [{ required: true, validator: this.pidPass, trigger: 'change' }],
        fee: [
          { required: true, message: '请输入费用', trigger: 'blur' },
          {
            required: true,
            validator: this.feePass,
            trigger: 'blur',
          },
        ],
        position_fee: [
          { required: true, message: '请输入费用', trigger: 'blur' },
          {
            required: true,
            validator: this.feePass,
            trigger: 'blur',
          },
        ],
      },
      saveLoading: false,
      isNext: false,
    }
  },
  methods: {
    getTitleName(ids) {
      if (!isArray(ids)) return ''

      let name = ''
      let children = JSON.parse(JSON.stringify(this.parentArr))

      ids.forEach(id => {
        name += `${name ? '/' : ''}${children.find(p => p.id === id)?.name}`
        children = children.find(p => p.id === id)?.children || []
      })

      return name
    },
    onVisibleChange(e) {
      this.$nextTick(() => {
        if (e === false) {
          // 面板关闭了，此事件发生在 change 之后，_ids 就是 选中的数据
          if (this._ids) {
            this.$set(this.postForm, 'ids', JSON.parse(JSON.stringify(this._ids)))
            this.$refs.idsFormItem.clearValidate() // 辣鸡，还要手动移除
          }
        } else {
          // 面板打开了
          this._ids = this.postForm.ids || []
        }
      })
    },
    getPosition(stepId = [], names = []) {
      let all = this.parentArr
      stepId.forEach((s, sI) => {
        const position = all.find((a) => a.id === s)
        if (position) {
          const { children, ...others } = position
          names.push(others)
          if (children?.length) {
            all = position.children
          }
        }
      })
      console.log(names)
      return names
    },
    // 获取职务详情
    getPositionDetail(id) {
      this.loading = true
      positionDetail(id)
        .then((res) => {
          const { data } = res
          this.postForm = Object.assign({}, this.postForm, data)
          this.loading = false
        })
        .catch((err) => {})
    },
    // 获取所属组织列表
    getParentList() {
      ParentList({ is_show_none: 0, is_all: 1 })
        .then((response) => {
          this.parentArr = response.data
        })
        .catch((err) => {})
    },
    handleExportCatalogChange(e) {
      console.log('cascader change: ', e)
      if (this._ids !== undefined && this._ids?.length > e.length) {
        // 在删除
        this.postForm.ids = e
      }
      this._ids = e // 临时记录的

      if (!this.postForm.id) {
        if (e.length) {
          setTimeout(() => {
            this.$refs['postForm'].clearValidate('ids')
          }, 5)
        }

        return
      } // 新增不需要收起，但是 cascader 用了 v-model 之后多选勾完一项就会自动收起

      if (!this.$refs.editCascader) return
      // Invalid left-hand side in assignment expression. (358:6)
      // this.$refs.editCascader?.dropDownVisible = false
      this.$refs.editCascader.dropDownVisible = false
    },
    // 上级组织验证
    pidPass(rule, value, callback) {
      // console.log(value, this.parentArr, 'value')
      if ((value != undefined && value.length) || this.postForm.pname) {
        callback()
      } else {
        callback(callback(new Error('请选择上级架构')))
      }
    },
    // 职务名称验证
    namePass(rule, value, callback) {
      if (value === '预备会员') {
        callback(callback(new Error("不允许填写'预备会员'名称")))
      } else {
        callback()
      }
    },
    // 费用验证
    feePass(rule, value, callback) {
      if (value < 0) {
        callback(callback(new Error('费用不小于0')))
      } else {
        callback()
      }
    },
    randomString,
    createMultiple() {
      this.saveLoading = true

      manager = new AQM({ maxParallel: 5 })

      this.selectedUsers.forEach((row) => {
        try {
          manager.push(() => {
            return new Promise((resolve) => {
              const idList = row.ids
              const data = { ...this.postForm }
              if (idList) {
                data.join_organization_id = idList[0]
                data.organization_id = idList[idList.length - 1]
              }
              data.id = this.isCopy ? 0 : data.id

              SavePosition(data)
                .then(({ code, msg }) => {
                  row.status = code
                  row.errMsg = msg
                  if (code === 1) row.progress = 100
                  resolve(row)
                })
                .catch((err) => {
                  row.status = 0
                  row.errMsg = err.msg || '出错了'
                  resolve(row)
                })
            })
          })
        } catch (e) {
          console.log(e)
        }
      })

      let resultList = []
      manager
        .start(function (res) {
          // 每一次请求成功的回调
          resultList.push(res)
        })
        .then(() => {
          console.log(resultList)
          this.saveLoading = false
          this.updateList()
        })
        .catch((err) => {
          // console.log(err)
          this.loading = false
          this.saveLoading = false
        })
    },
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = { ...this.postForm }
          console.log(data,'data');
          if (!data.id && data.ids?.length > 1) {
            // 新增，支持批量创建职务
            // 数据结构参考如： [[32],[153,156]]
            // 批量新建
            this.createMultiple()
            return
          }

          if (data.ids && data.ids.length) {
            if (!data.id) {
              data.join_organization_id = data.ids[0][0]
              data.organization_id = data.ids[0][data.ids[0].length - 1]
            } else {
              data.join_organization_id = data.ids[0]
              data.organization_id = data.ids[data.ids.length - 1]
            }
          }
          data.id = this.isCopy ? 0 : data.id
          this.saveLoading = true
          SavePosition(data)
            .then((res) => {
              this.updateList()
              if (!data.id) {
                this.$refs[formName].resetFields()
              }
              this.saveLoading = false
              this.openDialog = false
            })
            .catch((err) => {
              this.saveLoading = false
            })
        } else {
          return false
        }
      })
    },
    // 取消
    cancel(formName) {
      this.openDialog = false
      if (!this.postForm.id) {
        this.$refs[formName].resetFields()
      }
    },
    next() {
      this.$refs['postForm'].validate((valid) => {
        if (valid) {
          // 列举所选职务，然后多一个确认的批量进度界面
          this.isNext = true
        }
      })
    },
    onClickDelete(item, index) {
      const targetIdList = item.ids
      // this.selectedUsers.splice(index, 1)
      let delIndex = -1
      this.postForm.ids.forEach((idList, idListI) => {
        if (JSON.stringify(idList) === JSON.stringify(targetIdList)) {
          delIndex = idListI
        }
      })
      if (delIndex !== -1) this.postForm.ids.splice(delIndex, 1)

      if (this.postForm?.ids?.length === 0) {
        this.isNext = false
        this.$refs.idsFormItem.resetField()
      }
    },
    /**
     * 重置选项面板，把之前选中的
     * @param refName
     */
    resetCascader(refName) {
      if (!this.$refs[refName]) return
      // 清空地区选中节点和高亮节点
      this.$nextTick(() => {
        this.$refs[refName].$refs.panel.clearCheckedNodes()
        this.$refs[refName].$refs.panel.activePath = []
      })
    }
  },
  watch: {
    openDialog(val) {
      if (val) {
        this.postForm.id = this.ID
        this.isNext = false
        this.getParentList()

        if (this.ID) {
          this.resetCascader('editCascader')
          this.getPositionDetail({ id: this.ID })
        } else {
          this.resetCascader('addCascader')
          this.$nextTick(() => {
            this.$refs['postForm'].resetFields()
            this.configArr.map((item) => (this.postForm[item.key] = 0))
            this.postForm.pname = ''
          })
        }
      }
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('closeDialog', val)
      },
    },
    selectedUsers: {
      get() {
        return this.isNext
          ? this.postForm?.ids?.map((idList) => {
              return {
                ids: idList,
                positionList: this.getPosition(idList),
                progress: 0,
                status: -1,
                errMsg: '',
              }
            }) || []
          : []
      },
      set(val) {
        console.log(val, '这个没反应！是因为数组类型吗？')
        // TODO: 可以后悔删除一些架构
        // this.$set(this.postForm, 'ids', val.map(v => v.ids))
      },
    },
    tenantVersion() {
      return this.$store.getters.tenant.version
    },
    // 设置版本权限配置
    settingConfigArr() {
      return this.tenantVersion === 'school'
        ? this.schoolConfigArr
        : this.configArr
    },
  },
}
</script>

<style lang="scss" scoped>
.fee-input {
  width: 75%;
  ::v-deep.el-input__inner {
    background-color: transparent;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 6px;
  span + span {
    margin-left: 4px;
  }
}
</style>
