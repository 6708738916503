<template>
  <el-dialog
    title="修改组织架构 | 会内职务"
    :visible.sync="openDialog"
    width="500px"
    :show-close="true"
    class="dialog-vertical"
  >
    <!-- 用了 .sync 就不用重复处理关闭事件了：@close="cancel('transFormData')" -->
    <el-form
      size="medium"
      :model="transFormData"
      :rules="rules"
      label-position="right"
      label-width="90px"
      class="small-form"
      ref="transFormData"
    >
      <el-form-item :label="'组织架构：'" prop="ids">
        <el-cascader
          style="width: 80%"
          v-model="transFormData.ids"
          :options="parentArr"
          ref="CascaderRef"
          :props="{
            value: 'id',
            label: 'name',
            checkStrictly: true,
            expandTrigger: 'hover',
            emitPath: true,
          }"
          placeholder="请选择"
          @change="handleSelect"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="会内职务：" prop="position_id">
        <el-select
          v-model="transFormData.position_id"
          :placeholder="
            !transFormData.ids.length
              ? '请先选择组织架构'
              : loadingPosition
              ? '职务加载中...'
              : '请选择会内职务'
          "
          style="width: 80%"
          :disabled="!transFormData.ids.length || loadingPosition"
        >
          <el-option
            v-for="item in postList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="会费状态：">
        <el-radio-group v-model="transFormData.pay_status">
          <el-radio
            :label="pay.id"
            v-for="pay in payStatusList"
            :key="pay.id"
            >{{ pay.name }}</el-radio
          >
        </el-radio-group>
        <p class="info mTop">
          <i
            class="icon el-icon-info"
          />缴费状态关系到会员是否需要重新缴纳更改后的会费
        </p>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="cancel">取消</el-button>
      <el-button
        type="primary"
        @click="submit('transFormData')"
        :loading="saveLoading"
        :disabled="loadingPosition"
        >转移</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import {
  ParentList,
  PostOptions,
  MovePosition,
} from '../../api/meeting-post/post-list'
export default {
  model: {
    prop: 'open',
    event: 'closeDialog',
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    dataList: {
      type: [Number, String],
    },
    updateList: {
      type: Function,
    },
  },
  data() {
    return {
      payStatusList: [
        { id: 0, name: '未缴费' },
        { id: 1, name: '已缴费' },
        { id: -1, name: '保持用户当前会费状态' },
      ],
      parentArr: [],
      postList: [],
      transFormData: {
        ids: [],
        type: 2,
        pay_status: -1,
        position_id: '',
        join_organization_id: '',
      },
      rules: {
        ids: [{ required: true, validator: this.ids, trigger: 'change' }],
        position_id: [
          { required: true, message: '请选择会内职务', trigger: 'change' },
        ],
      },
      loadingPosition: false,
      saveLoading: false,
    }
  },
  methods: {
    // 获取架构列表
    getParentList() {
      ParentList({
        is_all: 1,
        is_show_none: 0,
        only_have_position: 1,
      })
        .then((res) => {
          this.parentArr = res.data
        })
        .catch((err) => {})
    },
    // 选择组织架构操作
    handleSelect(e) {
      if (!e.length) return
      const data = {}
      data.organization_id = e[e.length - 1]
      data.is_show_prepare = 0
      this.transFormData.join_organization_id = e[e.length - 1]
      this.loadingPosition = true
      this.$refs.CascaderRef.dropDownVisible = false
      // 获取该架构下的职务
      PostOptions(data)
        .then((res) => {
          this.loadingPosition = false
          this.postList = res.data
        })
        .catch((err) => {
          this.loadingPosition = false
        })
    },
    // 组织架构验证
    ids(rule, value, callback) {
      if (value != undefined && value.length) {
        callback()
      } else {
        callback(callback(new Error('请选择组织架构')))
      }
    },
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const { ids, ...others } = this.transFormData
          this.$emit('confirm', others)
          // this.saveLoading = true
          this.cancel()
        } else {
          return false
        }
      })
    },
    // 取消
    cancel(formName = 'transFormData') {
      if (this.openDialog) {
        this.openDialog = false
        this.$refs[formName]?.resetFields()
        setTimeout(() => {
          this.$refs[formName]?.clearValidate('ids')
        }, 50)
      }
      this.resetCascader('CascaderRef')
    },
    resetCascader(refName) {
      if (!this.$refs[refName]) return
      // 清空地区选中节点和高亮节点
      this.$nextTick(() => {
        this.$refs[refName].$refs.panel.clearCheckedNodes()
        this.$refs[refName].$refs.panel.activePath = []
      })
    }
  },
  created() {
    this.getParentList()
  },
  computed: {
    openDialog: {
      get() {
        return this.open
      },
      set(val) {
        this.$emit('closeDialog', val)
      },
    },
    tenantVersion() {
      return this.$store.getters.tenant.version
    },
  },
  watch: {
    openDialog(val) {
      if (val) {
        setTimeout(() => {
          this.$refs['transFormData']?.clearValidate('ids')
        }, 50)
      }
    }
  }
}
</script>

<style></style>
